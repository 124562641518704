import React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../components/Layout"
import { ContactSection } from "../commonComponents"

const styles = {
  fontContact: {
    color: "#fff",
    fontFamily: "DM Sans",
  },
  alignContent: {
    display: "flex",
    alignItems: "center",
  },
}

export default function Contact() {
  return (
    <Layout
      title="Contact IrisAgent For Any Queries, Feedback, Or Suggestions"
      keywords="IrisAgent, contact us, feedback"
      description="Contact us for any queries, feedback or suggestions you have and we will explain how IrisAgent can help you solve your customer support related issues with our AI Platform"
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/contact/"
        />
      </Helmet>
      {/*  ======= Breadcrumbs =======  */}
      {/*  <section className="breadcrumbs">
      <div className="container">

        <div className="d-flex justify-content-between align-items-center">
          <h2>Book Demo</h2>
          <ol>
            <li><a href="index.html">Home</a></li>
            <li>Demo</li>
          </ol>
        </div>

      </div>
    </section>  */}
      {/*  End Breadcrumbs  */}

      <section
        className="inner-page"
        style={{
          paddingTop: "80px",
        }}
      >
        {/*  ======= Contact Section =======  */}

        <section id="contact" className="contact">
          <div className="imageFilter"></div>
          <div className="info-container" style={styles.fontContact}>
            <h1 style={styles.fontContact}>
              We would <b>love to</b> hear
              from you!
            </h1>
            <p className="subtitle-mobile">
              Contact us for any queries, feedback or suggestions you <br />
              might have.
            </p>

            <p className="subtitle-web">
              Contact us for any queries, feedback <br />
              or suggestions you might have.
            </p>

            <iframe
              className="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3169.996743801561!2d-122.09695458517555!3d37.3899092798314!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb0c629d8e7e9%3A0xdf594160679e1937!2s1049%20El%20Monte%20Ave%20Ste%20C%20%23516%2C%20Mountain%20View%2C%20CA%2094040!5e0!3m2!1sen!2sus!4v1621818616465!5m2!1sen!2sus"
              frameBorder="0"
              allowFullScreen
            ></iframe>

            <p className="contact-info" style={{ marginBottom: "0" }}>
              1250 Borregas Ave
            </p>

            <p className="contact-info" style={{ marginBottom: "24px" }}>
              Sunnyvale, CA 94089
            </p>

            <div className="contact-info__margin" style={styles.alignContent}>
              <img
                height="18"
                src="/img/emailLogo.png"
                alt="email logo"
                style={{ marginRight: "16px" }}
              />
              <a
                className="contact-info"
                style={styles.fontContact}
                href="mailto:info@irisagent.com"
              >
                info@irisagent.com
              </a>
            </div>

            <div style={styles.alignContent}>
              <img
                height="24"
                src="/img/phoneLogo.png"
                alt="email logo"
                style={{ marginRight: "19px" }}
              />
              <a
                className="contact-info"
                style={styles.fontContact}
                href="tel:+1 617 249 3312"
              >
                +1 617 249 3312
              </a>
            </div>
          </div>
          <img
            className="imageMovil"
            src="/img/contactImage.svg"
            alt=""
            width={360}
          />
        </section>
        {/*  End Contact Section  */}
      </section>
      <ContactSection />
    </Layout>
  )
}
